import { ChangeDetectionStrategy, Component, inject, Input, signal, WritableSignal } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TJsonLd } from '@models/interfaces/seo';

@Component({
  standalone: true,
  selector: 'dsp-json-ld',
  templateUrl: './json-ld.component.html',
  styleUrls: ['./json-ld.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JsonLdComponent {
  @Input() set jsonLd(jsonLd: TJsonLd) {
    this.buildScript(jsonLd ?? null);
  }

  public $html: WritableSignal<SafeHtml> = signal(null);
  private sanitizer = inject(DomSanitizer);

  private buildScript(json: TJsonLd = null): void {
    if (!json) {
      this.$html.set(null);
      return;
    }

    const _html = `<script type="application/ld+json">${JSON.stringify(json)}</script>`;
    this.$html.set(this.sanitizer.bypassSecurityTrustHtml(_html));
  }
}
